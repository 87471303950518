/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

export type Catalog = {
  __typename?: "Catalog";
  category: Maybe<CatalogCategory>;
  categoryOrdinal: Scalars["Float"]["output"];
  /** category が Course の時に存在する */
  courseItems: Maybe<Array<CourseItem>>;
  description: Maybe<Scalars["String"]["output"]>;
  favorites: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  imageUrl: Maybe<Scalars["String"]["output"]>;
  invisible: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  /** subCategory が Wine の時に存在する */
  pairedFoods: Maybe<Array<Scalars["String"]["output"]>>;
  recommended: Scalars["Boolean"]["output"];
  subCategory: CatalogSubCategory;
  variations: Array<CatalogVariation>;
};

export type CatalogCategory = "Beverage" | "Course" | "Food";

export type CatalogSubCategory =
  | "Appetizer"
  | "BottleWineRed"
  | "BottleWineRose"
  | "BottleWineSparkling"
  | "BottleWineWhite"
  | "Course"
  | "CourseOption"
  | "Dessert"
  | "DimSum"
  | "GlassWine"
  | "Handy"
  | "LunchMenu"
  | "LunchSideMenu"
  | "Main"
  | "NonAlcoholic"
  | "NoodleRice"
  | "OtherDrink"
  | "Tea";

export type CatalogVariation = {
  __typename?: "CatalogVariation";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
};

export type CourseItem = {
  __typename?: "CourseItem";
  description: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type CreateCourseOrderItemCourseMenuDetailDetailInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreateCourseOrderItemCourseMenuDetailInput = {
  drinks: Array<CreateCourseOrderItemCourseMenuDetailDetailInput>;
  foods: Array<CreateCourseOrderItemCourseMenuDetailDetailInput>;
};

export type CreateCourseOrderItemInput = {
  courseCatalogId: Scalars["ID"]["input"];
  courseMenuDetail?: InputMaybe<CreateCourseOrderItemCourseMenuDetailInput>;
  courseOptionCatalogId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  price: Scalars["Int"]["input"];
  quantity: Scalars["Int"]["input"];
};

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  courseOrderItems: Array<CreateCourseOrderItemInput>;
  orderItems?: InputMaybe<Array<CreateOrderItemInput>>;
  visitId: Scalars["ID"]["input"];
};

export type CreateOrderItemCatalogInput = {
  catalogId: Scalars["ID"]["input"];
  catalogVariationId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  price: Scalars["Int"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type CreateOrderItemFreeInput = {
  name: Scalars["String"]["input"];
  price: Scalars["Int"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type CreateOrderItemInput =
  | { catalog: CreateOrderItemCatalogInput; free?: never }
  | { catalog?: never; free: CreateOrderItemFreeInput };

export type Customer = {
  __typename?: "Customer";
  id: Scalars["ID"]["output"];
  profile: Maybe<Profile>;
};

/** Autogenerated input type of CreateAuthSession */
export type DebugCreateAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

export type Gender = "Female" | "Male" | "NotApplicable" | "Unknown";

export type Membership = {
  __typename?: "Membership";
  customer: Customer;
  id: Scalars["ID"]["output"];
  lastVisitedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  totalVisitCount: Scalars["Int"]["output"];
};

export type MembershipVisit = {
  __typename?: "MembershipVisit";
  id: Scalars["ID"]["output"];
  membership: Membership;
};

export type MenuCategory = "Dinner" | "Lunch";

export type MenuType = "Dinner" | "Lunch";

export type Mutation = {
  __typename?: "Mutation";
  createOrder: Order;
  debugCreateAuthSession: ShopUser;
  registerCard: Maybe<Seat>;
  revokeCard: Maybe<Seat>;
  settle: Settlement;
  updateVisitNote: Visit;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationDebugCreateAuthSessionArgs = {
  input: DebugCreateAuthSessionInput;
};

export type MutationRegisterCardArgs = {
  input: RegisterCardInput;
};

export type MutationRevokeCardArgs = {
  input: RevokeCardInput;
};

export type MutationSettleArgs = {
  input: SettleInput;
};

export type MutationUpdateVisitNoteArgs = {
  input: UpdateVisitNoteInput;
};

export type Order = {
  __typename?: "Order";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  dailySequence: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  orderItems: Array<OrderItem>;
};

export type OrderCalculation = {
  __typename?: "OrderCalculation";
  /** サービス料 */
  serviceCharge: Scalars["Int"]["output"];
  /** サービス料率 */
  serviceChargePercentage: Scalars["Int"]["output"];
  subtotal: Scalars["Int"]["output"];
  tax: Scalars["Int"]["output"];
  totalAmount: Scalars["Int"]["output"];
};

export type OrderItem = {
  __typename?: "OrderItem";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  status: OrderItemStatus;
  takingTime: Scalars["Boolean"]["output"];
};

export type OrderItemStatus = "Canceled" | "Pending" | "Served";

export type Profile = {
  __typename?: "Profile";
  birthYear: Scalars["Int"]["output"];
  firstNameKana: Scalars["String"]["output"];
  gender: Gender;
  id: Scalars["ID"]["output"];
  lastNameKana: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  /** @deprecated `visits(onlyActive: true)` を利用する */
  activeVisits: Array<Visit>;
  /** Fetches a list of catalogs. */
  catalogs: Array<Catalog>;
  currentShop: Shop;
  shopUser: Maybe<ShopUser>;
  visit: Visit;
  visits: Array<Visit>;
};

export type QueryCatalogsArgs = {
  menuCategory: InputMaybe<MenuCategory>;
};

export type QueryVisitArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryVisitsArgs = {
  date: Scalars["ISO8601Date"]["input"];
  onlyActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlySettled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated input type of RegisterCard */
export type RegisterCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  seatId: Scalars["ID"]["input"];
  shopCardCode: Scalars["String"]["input"];
};

/** Autogenerated input type of RevokeCard */
export type RevokeCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  seatId: Scalars["ID"]["input"];
  shopCardId: Scalars["ID"]["input"];
};

export type Seat = {
  __typename?: "Seat";
  availableShopCard: Maybe<ShopCard>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  table: Table;
};

export type ServiceCharge = {
  __typename?: "ServiceCharge";
  dinner: Scalars["Int"]["output"];
  dinnerRate: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  lunch: Scalars["Int"]["output"];
  lunchRate: Scalars["Float"]["output"];
};

/** Autogenerated input type of Settle */
export type SettleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

export type Settlement = {
  __typename?: "Settlement";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export type Shop = {
  __typename?: "Shop";
  activeVisits: Array<Visit>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  seat: Seat;
  serviceCharge: ServiceCharge;
  table: Table;
  tables: Array<Table>;
};

export type ShopSeatArgs = {
  id: Scalars["ID"]["input"];
};

export type ShopTableArgs = {
  id: Scalars["ID"]["input"];
};

export type ShopTablesArgs = {
  category: InputMaybe<TableCategory>;
};

export type ShopCard = {
  __typename?: "ShopCard";
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type ShopUser = {
  __typename?: "ShopUser";
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  shop: Shop;
};

export type Table = {
  __typename?: "Table";
  category: TableCategory;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  seats: Array<Seat>;
};

export type TableCategory = "Counter" | "TableFormat";

/** Autogenerated input type of UpdateVisitNote */
export type UpdateVisitNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  shopNote: Scalars["String"]["input"];
};

export type Visit = {
  __typename?: "Visit";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  membershipVisits: Array<MembershipVisit>;
  menuType: MenuType;
  notServedOrderItemExists: Scalars["Boolean"]["output"];
  orderCalculation: OrderCalculation;
  orderItems: Array<OrderItem>;
  orders: Array<Order>;
  settlement: Maybe<Settlement>;
  shopNote: Maybe<Scalars["String"]["output"]>;
  visitSeatMaps: Array<VisitSeatMap>;
  visitorCount: Scalars["Int"]["output"];
};

export type VisitSeatMap = {
  __typename?: "VisitSeatMap";
  id: Scalars["ID"]["output"];
  seat: Seat;
};

export type SeatManagementSeatDrawer_SeatFragment = {
  __typename?: "Seat";
  id: string;
  name: string;
  availableShopCard: {
    __typename?: "ShopCard";
    id: string;
    code: string;
  } | null;
} & { " $fragmentName"?: "SeatManagementSeatDrawer_SeatFragment" };

export type SeatManagementSeatScreen_SeatFragment = {
  __typename?: "Seat";
  id: string;
  name: string;
  availableShopCard: {
    __typename?: "ShopCard";
    id: string;
    code: string;
  } | null;
} & { " $fragmentName"?: "SeatManagementSeatScreen_SeatFragment" };

export type UpdateVisitNoteMutationVariables = Exact<{
  input: UpdateVisitNoteInput;
}>;

export type UpdateVisitNoteMutation = {
  __typename?: "Mutation";
  updateVisitNote: { __typename?: "Visit" } & {
    " $fragmentRefs"?: { VisitUnitFragment: VisitUnitFragment };
  };
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: "Mutation";
  createOrder: { __typename?: "Order"; id: string };
};

export type FetchCatalogsQueryVariables = Exact<{
  visitId: Scalars["ID"]["input"];
}>;

export type FetchCatalogsQuery = {
  __typename?: "Query";
  catalogs: Array<
    { __typename?: "Catalog" } & {
      " $fragmentRefs"?: { CatalogUnitFragment: CatalogUnitFragment };
    }
  >;
  visit: { __typename?: "Visit" } & {
    " $fragmentRefs"?: { VisitUnitFragment: VisitUnitFragment };
  };
};

export type CatalogUnitFragment = {
  __typename?: "Catalog";
  id: string;
  name: string;
  description: string | null;
  category: CatalogCategory | null;
  subCategory: CatalogSubCategory;
  categoryOrdinal: number;
  pairedFoods: Array<string> | null;
  favorites: boolean;
  invisible: boolean;
  courseItems: Array<{
    __typename?: "CourseItem";
    name: string;
    description: string | null;
  }> | null;
  variations: Array<{
    __typename?: "CatalogVariation";
    id: string;
    name: string;
    price: number;
  }>;
} & { " $fragmentName"?: "CatalogUnitFragment" };

export type SettleMutationVariables = Exact<{
  input: SettleInput;
}>;

export type SettleMutation = {
  __typename?: "Mutation";
  settle: { __typename?: "Settlement"; id: string; createdAt: string };
};

export type FetchHistoryVisitQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchHistoryVisitQuery = {
  __typename?: "Query";
  visit: { __typename?: "Visit" } & {
    " $fragmentRefs"?: { HistoryVisitFragment: HistoryVisitFragment };
  };
};

export type HistoryVisitFragment = {
  __typename?: "Visit";
  id: string;
  createdAt: string;
  visitorCount: number;
  notServedOrderItemExists: boolean;
  visitSeatMaps: Array<{
    __typename?: "VisitSeatMap";
    id: string;
    seat: {
      __typename?: "Seat";
      id: string;
      name: string;
      table: {
        __typename?: "Table";
        id: string;
        name: string;
        category: TableCategory;
      };
    };
  }>;
  membershipVisits: Array<{
    __typename?: "MembershipVisit";
    id: string;
    membership: {
      __typename?: "Membership";
      id: string;
      totalVisitCount: number;
      lastVisitedAt: string | null;
      customer: {
        __typename?: "Customer";
        id: string;
        profile: {
          __typename?: "Profile";
          id: string;
          firstNameKana: string;
          lastNameKana: string;
        } | null;
      };
    };
  }>;
  orderCalculation: { __typename?: "OrderCalculation"; totalAmount: number };
  settlement: {
    __typename?: "Settlement";
    id: string;
    createdAt: string;
  } | null;
} & { " $fragmentName"?: "HistoryVisitFragment" };

export type FetchTablesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTablesQuery = {
  __typename?: "Query";
  currentShop: {
    __typename?: "Shop";
    tables: Array<{
      __typename?: "Table";
      id: string;
      name: string;
      category: TableCategory;
      seats: Array<{ __typename?: "Seat"; id: string; name: string }>;
    }>;
  };
};

export type FetchSeatManagementRegisterCardQueryVariables = Exact<{
  seatId: Scalars["ID"]["input"];
}>;

export type FetchSeatManagementRegisterCardQuery = {
  __typename?: "Query";
  currentShop: {
    __typename?: "Shop";
    seat: {
      __typename?: "Seat";
      id: string;
      name: string;
      table: {
        __typename?: "Table";
        id: string;
        name: string;
        category: TableCategory;
      };
    };
  };
};

export type RegisterCardMutationVariables = Exact<{
  input: RegisterCardInput;
}>;

export type RegisterCardMutation = {
  __typename?: "Mutation";
  registerCard: { __typename?: "Seat"; id: string } | null;
};

export type FetchTableByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchTableByIdQuery = {
  __typename?: "Query";
  currentShop: {
    __typename?: "Shop";
    table: {
      __typename?: "Table";
      id: string;
      name: string;
      category: TableCategory;
      seats: Array<
        { __typename?: "Seat"; id: string } & {
          " $fragmentRefs"?: {
            SeatManagementSeatScreen_SeatFragment: SeatManagementSeatScreen_SeatFragment;
            SeatManagementSeatDrawer_SeatFragment: SeatManagementSeatDrawer_SeatFragment;
          };
        }
      >;
    };
  };
};

export type FetchTablesByCategoryQueryVariables = Exact<{
  category: TableCategory;
}>;

export type FetchTablesByCategoryQuery = {
  __typename?: "Query";
  currentShop: {
    __typename?: "Shop";
    tables: Array<{
      __typename?: "Table";
      id: string;
      name: string;
      category: TableCategory;
      seats: Array<
        { __typename?: "Seat"; id: string } & {
          " $fragmentRefs"?: {
            SeatManagementSeatScreen_SeatFragment: SeatManagementSeatScreen_SeatFragment;
            SeatManagementSeatDrawer_SeatFragment: SeatManagementSeatDrawer_SeatFragment;
          };
        }
      >;
    }>;
  };
};

export type RevokeCardMutationVariables = Exact<{
  input: RevokeCardInput;
}>;

export type RevokeCardMutation = {
  __typename?: "Mutation";
  revokeCard:
    | ({ __typename?: "Seat"; id: string } & {
        " $fragmentRefs"?: {
          SeatManagementSeatScreen_SeatFragment: SeatManagementSeatScreen_SeatFragment;
          SeatManagementSeatDrawer_SeatFragment: SeatManagementSeatDrawer_SeatFragment;
        };
      })
    | null;
};

export type FetchVisitQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchVisitQuery = {
  __typename?: "Query";
  visit: { __typename?: "Visit" } & {
    " $fragmentRefs"?: { VisitUnitFragment: VisitUnitFragment };
  };
};

export type FetchVisitsQueryVariables = Exact<{
  date: Scalars["ISO8601Date"]["input"];
  onlyActive: Scalars["Boolean"]["input"];
  onlySettled: Scalars["Boolean"]["input"];
}>;

export type FetchVisitsQuery = {
  __typename?: "Query";
  visits: Array<
    { __typename?: "Visit" } & {
      " $fragmentRefs"?: { VisitUnitFragment: VisitUnitFragment };
    }
  >;
};

export type VisitUnitFragment = {
  __typename?: "Visit";
  id: string;
  createdAt: string;
  visitorCount: number;
  notServedOrderItemExists: boolean;
  menuType: MenuType;
  shopNote: string | null;
  settlement: {
    __typename?: "Settlement";
    id: string;
    createdAt: string;
  } | null;
  visitSeatMaps: Array<{
    __typename?: "VisitSeatMap";
    id: string;
    seat: {
      __typename?: "Seat";
      id: string;
      name: string;
      table: {
        __typename?: "Table";
        id: string;
        name: string;
        category: TableCategory;
      };
    };
  }>;
  membershipVisits: Array<{
    __typename?: "MembershipVisit";
    id: string;
    membership: {
      __typename?: "Membership";
      id: string;
      totalVisitCount: number;
      lastVisitedAt: string | null;
      customer: {
        __typename?: "Customer";
        id: string;
        profile: {
          __typename?: "Profile";
          id: string;
          firstNameKana: string;
          lastNameKana: string;
        } | null;
      };
    };
  }>;
  orderCalculation: {
    __typename?: "OrderCalculation";
    totalAmount: number;
    subtotal: number;
    tax: number;
    serviceCharge: number;
    serviceChargePercentage: number;
  };
  orders: Array<{
    __typename?: "Order";
    id: string;
    dailySequence: number;
    createdAt: string;
    orderItems: Array<{
      __typename?: "OrderItem";
      id: string;
      name: string;
      quantity: number;
      price: number;
      takingTime: boolean;
      status: OrderItemStatus;
      createdAt: string;
    }>;
  }>;
  orderItems: Array<{
    __typename?: "OrderItem";
    id: string;
    name: string;
    quantity: number;
    price: number;
    takingTime: boolean;
    status: OrderItemStatus;
    createdAt: string;
  }>;
} & { " $fragmentName"?: "VisitUnitFragment" };

export type AuthProvider_DebugCreateAuthSessionMutationVariables = Exact<{
  input: DebugCreateAuthSessionInput;
}>;

export type AuthProvider_DebugCreateAuthSessionMutation = {
  __typename?: "Mutation";
  debugCreateAuthSession: { __typename?: "ShopUser" } & {
    " $fragmentRefs"?: {
      AuthProvider_ShopUserFragment: AuthProvider_ShopUserFragment;
    };
  };
};

export type AuthProvider_ShopUserFragment = {
  __typename?: "ShopUser";
  id: string;
  email: string;
  shop: { __typename?: "Shop"; id: string; name: string };
} & { " $fragmentName"?: "AuthProvider_ShopUserFragment" };

export type AuthProvider_ShopUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AuthProvider_ShopUserQuery = {
  __typename?: "Query";
  shopUser:
    | ({ __typename?: "ShopUser" } & {
        " $fragmentRefs"?: {
          AuthProvider_ShopUserFragment: AuthProvider_ShopUserFragment;
        };
      })
    | null;
};

export const SeatManagementSeatDrawer_SeatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatDrawer_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SeatManagementSeatDrawer_SeatFragment, unknown>;
export const SeatManagementSeatScreen_SeatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatScreen_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SeatManagementSeatScreen_SeatFragment, unknown>;
export const CatalogUnitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CatalogUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Catalog" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "subCategory" } },
          { kind: "Field", name: { kind: "Name", value: "categoryOrdinal" } },
          { kind: "Field", name: { kind: "Name", value: "pairedFoods" } },
          { kind: "Field", name: { kind: "Name", value: "favorites" } },
          { kind: "Field", name: { kind: "Name", value: "invisible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "courseItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "variations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogUnitFragment, unknown>;
export const HistoryVisitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HistoryVisit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HistoryVisitFragment, unknown>;
export const VisitUnitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          { kind: "Field", name: { kind: "Name", value: "menuType" } },
          { kind: "Field", name: { kind: "Name", value: "shopNote" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceCharge" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceChargePercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dailySequence" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "takingTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "takingTime" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VisitUnitFragment, unknown>;
export const AuthProvider_ShopUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthProvider_ShopUserFragment, unknown>;
export const UpdateVisitNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateVisitNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateVisitNoteInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateVisitNote" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VisitUnit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          { kind: "Field", name: { kind: "Name", value: "menuType" } },
          { kind: "Field", name: { kind: "Name", value: "shopNote" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceCharge" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceChargePercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dailySequence" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "takingTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "takingTime" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVisitNoteMutation,
  UpdateVisitNoteMutationVariables
>;
export const CreateOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateOrderInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrderMutation, CreateOrderMutationVariables>;
export const FetchCatalogsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchCatalogs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "visitId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "catalogs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CatalogUnit" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "visitId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VisitUnit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CatalogUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Catalog" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "subCategory" } },
          { kind: "Field", name: { kind: "Name", value: "categoryOrdinal" } },
          { kind: "Field", name: { kind: "Name", value: "pairedFoods" } },
          { kind: "Field", name: { kind: "Name", value: "favorites" } },
          { kind: "Field", name: { kind: "Name", value: "invisible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "courseItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "variations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          { kind: "Field", name: { kind: "Name", value: "menuType" } },
          { kind: "Field", name: { kind: "Name", value: "shopNote" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceCharge" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceChargePercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dailySequence" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "takingTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "takingTime" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchCatalogsQuery, FetchCatalogsQueryVariables>;
export const SettleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Settle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SettleInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "settle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettleMutation, SettleMutationVariables>;
export const FetchHistoryVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchHistoryVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HistoryVisit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HistoryVisit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchHistoryVisitQuery,
  FetchHistoryVisitQueryVariables
>;
export const FetchTablesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchTables" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tables" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchTablesQuery, FetchTablesQueryVariables>;
export const FetchSeatManagementRegisterCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchSeatManagementRegisterCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "seatId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "seatId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchSeatManagementRegisterCardQuery,
  FetchSeatManagementRegisterCardQueryVariables
>;
export const RegisterCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RegisterCardInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterCardMutation,
  RegisterCardMutationVariables
>;
export const FetchTableByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchTableById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "table" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "SeatManagementSeatScreen_Seat",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "SeatManagementSeatDrawer_Seat",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatScreen_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatDrawer_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchTableByIdQuery, FetchTableByIdQueryVariables>;
export const FetchTablesByCategoryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchTablesByCategory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "category" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TableCategory" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tables" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "category" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "category" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "SeatManagementSeatScreen_Seat",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "SeatManagementSeatDrawer_Seat",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatScreen_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatDrawer_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchTablesByCategoryQuery,
  FetchTablesByCategoryQueryVariables
>;
export const RevokeCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RevokeCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RevokeCardInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "revokeCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "SeatManagementSeatScreen_Seat",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "SeatManagementSeatDrawer_Seat",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatScreen_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SeatManagementSeatDrawer_Seat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Seat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableShopCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevokeCardMutation, RevokeCardMutationVariables>;
export const FetchVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VisitUnit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          { kind: "Field", name: { kind: "Name", value: "menuType" } },
          { kind: "Field", name: { kind: "Name", value: "shopNote" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceCharge" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceChargePercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dailySequence" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "takingTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "takingTime" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchVisitQuery, FetchVisitQueryVariables>;
export const FetchVisitsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchVisits" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "date" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ISO8601Date" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onlyActive" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onlySettled" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "date" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "date" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "onlyActive" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "onlyActive" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "onlySettled" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "onlySettled" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VisitUnit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "visitorCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "notServedOrderItemExists" },
          },
          { kind: "Field", name: { kind: "Name", value: "menuType" } },
          { kind: "Field", name: { kind: "Name", value: "shopNote" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitSeatMaps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "table" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membershipVisits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalVisitCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastVisitedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstNameKana",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastNameKana",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderCalculation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "subtotal" } },
                { kind: "Field", name: { kind: "Name", value: "tax" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceCharge" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceChargePercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dailySequence" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "takingTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "takingTime" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchVisitsQuery, FetchVisitsQueryVariables>;
export const AuthProvider_DebugCreateAuthSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AuthProvider_DebugCreateAuthSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DebugCreateAuthSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "debugCreateAuthSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthProvider_ShopUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthProvider_DebugCreateAuthSessionMutation,
  AuthProvider_DebugCreateAuthSessionMutationVariables
>;
export const AuthProvider_ShopUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shopUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthProvider_ShopUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthProvider_ShopUserQuery,
  AuthProvider_ShopUserQueryVariables
>;
