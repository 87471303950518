import { Stack } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

import { Header } from "../Header";

import { AuthenticatedMenu } from "./AuthenticatedMenu";

import { useIdApp } from "src/hooks/useIdApp";
import { useAuth } from "src/providers/AuthProvider";

type Props = {
  children: ReactNode;
};

export const AuthenticatedMainLayout: FC<Props> = ({ children }) => {
  const { authState } = useAuth();
  const { redirectToSignOutUrl } = useIdApp();

  const handleLogout = async () => {
    redirectToSignOutUrl();
  };

  return (
    <Stack minH="$100vh" spacing={4}>
      <Header
        rightContent={
          <AuthenticatedMenu
            shopUser={authState.shopUser}
            onLogout={handleLogout}
          />
        }
      />
      <Stack spacing={8}>{children}</Stack>
    </Stack>
  );
};
