import { ApolloProvider } from "@apollo/client";
import { AppProps } from "next/app";
import Head from "next/head";

import { FontsLoader } from "../components/FontLoader";

import { AuthorizedGuard } from "src/components/AuthorizedGuard";
import { useApollo } from "src/hooks/useApollo";
import { AuthProvider } from "src/providers/AuthProvider";
import { StaffHandyChakraProvider } from "src/providers/StaffHandyChakraProvider";

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps.initialApolloState);

  return (
    <>
      <Head>
        <title>Speria for Staff Handy</title>
        <meta name="robots" content="noindex" />
      </Head>

      <ApolloProvider client={client}>
        <StaffHandyChakraProvider>
          <AuthProvider>
            <FontsLoader>
              <AuthorizedGuard>
                <Component {...pageProps} />
              </AuthorizedGuard>
            </FontsLoader>
          </AuthProvider>
        </StaffHandyChakraProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
