/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "fragment SeatManagementSeatDrawer_Seat on Seat {\n  id\n  name\n  availableShopCard {\n    id\n    code\n  }\n}":
    types.SeatManagementSeatDrawer_SeatFragmentDoc,
  "fragment SeatManagementSeatScreen_Seat on Seat {\n  id\n  name\n  availableShopCard {\n    id\n    code\n  }\n}":
    types.SeatManagementSeatScreen_SeatFragmentDoc,
  "mutation UpdateVisitNote($input: UpdateVisitNoteInput!) {\n  updateVisitNote(input: $input) {\n    ...VisitUnit\n  }\n}":
    types.UpdateVisitNoteDocument,
  "mutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n  }\n}\n\nquery FetchCatalogs($visitId: ID!) {\n  catalogs {\n    ...CatalogUnit\n  }\n  visit(id: $visitId) {\n    ...VisitUnit\n  }\n}\n\nfragment CatalogUnit on Catalog {\n  id\n  name\n  description\n  category\n  subCategory\n  categoryOrdinal\n  pairedFoods\n  favorites\n  invisible\n  courseItems {\n    name\n    description\n  }\n  variations {\n    id\n    name\n    price\n  }\n}":
    types.CreateOrderDocument,
  "mutation Settle($input: SettleInput!) {\n  settle(input: $input) {\n    id\n    createdAt\n  }\n}":
    types.SettleDocument,
  "query FetchHistoryVisit($id: ID!) {\n  visit(id: $id) {\n    ...HistoryVisit\n  }\n}":
    types.FetchHistoryVisitDocument,
  "fragment HistoryVisit on Visit {\n  id\n  createdAt\n  visitorCount\n  notServedOrderItemExists\n  visitSeatMaps {\n    id\n    seat {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      totalVisitCount\n      lastVisitedAt\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n  orderCalculation {\n    totalAmount\n  }\n  settlement {\n    id\n    createdAt\n  }\n}":
    types.HistoryVisitFragmentDoc,
  "query FetchTables {\n  currentShop {\n    tables {\n      id\n      name\n      category\n      seats {\n        id\n        name\n      }\n    }\n  }\n}":
    types.FetchTablesDocument,
  "query FetchSeatManagementRegisterCard($seatId: ID!) {\n  currentShop {\n    seat(id: $seatId) {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n}\n\nmutation RegisterCard($input: RegisterCardInput!) {\n  registerCard(input: $input) {\n    id\n  }\n}":
    types.FetchSeatManagementRegisterCardDocument,
  "query FetchTableById($id: ID!) {\n  currentShop {\n    table(id: $id) {\n      id\n      name\n      category\n      seats {\n        id\n        ...SeatManagementSeatScreen_Seat\n        ...SeatManagementSeatDrawer_Seat\n      }\n    }\n  }\n}\n\nquery FetchTablesByCategory($category: TableCategory!) {\n  currentShop {\n    tables(category: $category) {\n      id\n      name\n      category\n      seats {\n        id\n        ...SeatManagementSeatScreen_Seat\n        ...SeatManagementSeatDrawer_Seat\n      }\n    }\n  }\n}\n\nmutation RevokeCard($input: RevokeCardInput!) {\n  revokeCard(input: $input) {\n    id\n    ...SeatManagementSeatScreen_Seat\n    ...SeatManagementSeatDrawer_Seat\n  }\n}":
    types.FetchTableByIdDocument,
  "query FetchVisit($id: ID!) {\n  visit(id: $id) {\n    ...VisitUnit\n  }\n}":
    types.FetchVisitDocument,
  "mutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n  }\n}":
    types.CreateOrderDocument,
  "query FetchVisits($date: ISO8601Date!, $onlyActive: Boolean!, $onlySettled: Boolean!) {\n  visits(date: $date, onlyActive: $onlyActive, onlySettled: $onlySettled) {\n    ...VisitUnit\n  }\n}\n\nfragment VisitUnit on Visit {\n  id\n  createdAt\n  visitorCount\n  notServedOrderItemExists\n  menuType\n  shopNote\n  settlement {\n    id\n    createdAt\n  }\n  visitSeatMaps {\n    id\n    seat {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      totalVisitCount\n      lastVisitedAt\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n  orderCalculation {\n    totalAmount\n    subtotal\n    tax\n    serviceCharge\n    serviceChargePercentage\n  }\n  orders {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      name\n      quantity\n      price\n      takingTime\n      status\n      createdAt\n    }\n  }\n  orderItems {\n    id\n    name\n    quantity\n    price\n    takingTime\n    status\n    createdAt\n  }\n}":
    types.FetchVisitsDocument,
  "mutation AuthProvider_DebugCreateAuthSession($input: DebugCreateAuthSessionInput!) {\n  debugCreateAuthSession(input: $input) {\n    ...AuthProvider_ShopUser\n  }\n}\n\nfragment AuthProvider_ShopUser on ShopUser {\n  id\n  email\n  shop {\n    id\n    name\n  }\n}\n\nquery AuthProvider_ShopUser {\n  shopUser {\n    ...AuthProvider_ShopUser\n  }\n}":
    types.AuthProvider_DebugCreateAuthSessionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment SeatManagementSeatDrawer_Seat on Seat {\n  id\n  name\n  availableShopCard {\n    id\n    code\n  }\n}",
): (typeof documents)["fragment SeatManagementSeatDrawer_Seat on Seat {\n  id\n  name\n  availableShopCard {\n    id\n    code\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment SeatManagementSeatScreen_Seat on Seat {\n  id\n  name\n  availableShopCard {\n    id\n    code\n  }\n}",
): (typeof documents)["fragment SeatManagementSeatScreen_Seat on Seat {\n  id\n  name\n  availableShopCard {\n    id\n    code\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation UpdateVisitNote($input: UpdateVisitNoteInput!) {\n  updateVisitNote(input: $input) {\n    ...VisitUnit\n  }\n}",
): (typeof documents)["mutation UpdateVisitNote($input: UpdateVisitNoteInput!) {\n  updateVisitNote(input: $input) {\n    ...VisitUnit\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n  }\n}\n\nquery FetchCatalogs($visitId: ID!) {\n  catalogs {\n    ...CatalogUnit\n  }\n  visit(id: $visitId) {\n    ...VisitUnit\n  }\n}\n\nfragment CatalogUnit on Catalog {\n  id\n  name\n  description\n  category\n  subCategory\n  categoryOrdinal\n  pairedFoods\n  favorites\n  invisible\n  courseItems {\n    name\n    description\n  }\n  variations {\n    id\n    name\n    price\n  }\n}",
): (typeof documents)["mutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n  }\n}\n\nquery FetchCatalogs($visitId: ID!) {\n  catalogs {\n    ...CatalogUnit\n  }\n  visit(id: $visitId) {\n    ...VisitUnit\n  }\n}\n\nfragment CatalogUnit on Catalog {\n  id\n  name\n  description\n  category\n  subCategory\n  categoryOrdinal\n  pairedFoods\n  favorites\n  invisible\n  courseItems {\n    name\n    description\n  }\n  variations {\n    id\n    name\n    price\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation Settle($input: SettleInput!) {\n  settle(input: $input) {\n    id\n    createdAt\n  }\n}",
): (typeof documents)["mutation Settle($input: SettleInput!) {\n  settle(input: $input) {\n    id\n    createdAt\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchHistoryVisit($id: ID!) {\n  visit(id: $id) {\n    ...HistoryVisit\n  }\n}",
): (typeof documents)["query FetchHistoryVisit($id: ID!) {\n  visit(id: $id) {\n    ...HistoryVisit\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment HistoryVisit on Visit {\n  id\n  createdAt\n  visitorCount\n  notServedOrderItemExists\n  visitSeatMaps {\n    id\n    seat {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      totalVisitCount\n      lastVisitedAt\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n  orderCalculation {\n    totalAmount\n  }\n  settlement {\n    id\n    createdAt\n  }\n}",
): (typeof documents)["fragment HistoryVisit on Visit {\n  id\n  createdAt\n  visitorCount\n  notServedOrderItemExists\n  visitSeatMaps {\n    id\n    seat {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      totalVisitCount\n      lastVisitedAt\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n  orderCalculation {\n    totalAmount\n  }\n  settlement {\n    id\n    createdAt\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchTables {\n  currentShop {\n    tables {\n      id\n      name\n      category\n      seats {\n        id\n        name\n      }\n    }\n  }\n}",
): (typeof documents)["query FetchTables {\n  currentShop {\n    tables {\n      id\n      name\n      category\n      seats {\n        id\n        name\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchSeatManagementRegisterCard($seatId: ID!) {\n  currentShop {\n    seat(id: $seatId) {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n}\n\nmutation RegisterCard($input: RegisterCardInput!) {\n  registerCard(input: $input) {\n    id\n  }\n}",
): (typeof documents)["query FetchSeatManagementRegisterCard($seatId: ID!) {\n  currentShop {\n    seat(id: $seatId) {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n}\n\nmutation RegisterCard($input: RegisterCardInput!) {\n  registerCard(input: $input) {\n    id\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchTableById($id: ID!) {\n  currentShop {\n    table(id: $id) {\n      id\n      name\n      category\n      seats {\n        id\n        ...SeatManagementSeatScreen_Seat\n        ...SeatManagementSeatDrawer_Seat\n      }\n    }\n  }\n}\n\nquery FetchTablesByCategory($category: TableCategory!) {\n  currentShop {\n    tables(category: $category) {\n      id\n      name\n      category\n      seats {\n        id\n        ...SeatManagementSeatScreen_Seat\n        ...SeatManagementSeatDrawer_Seat\n      }\n    }\n  }\n}\n\nmutation RevokeCard($input: RevokeCardInput!) {\n  revokeCard(input: $input) {\n    id\n    ...SeatManagementSeatScreen_Seat\n    ...SeatManagementSeatDrawer_Seat\n  }\n}",
): (typeof documents)["query FetchTableById($id: ID!) {\n  currentShop {\n    table(id: $id) {\n      id\n      name\n      category\n      seats {\n        id\n        ...SeatManagementSeatScreen_Seat\n        ...SeatManagementSeatDrawer_Seat\n      }\n    }\n  }\n}\n\nquery FetchTablesByCategory($category: TableCategory!) {\n  currentShop {\n    tables(category: $category) {\n      id\n      name\n      category\n      seats {\n        id\n        ...SeatManagementSeatScreen_Seat\n        ...SeatManagementSeatDrawer_Seat\n      }\n    }\n  }\n}\n\nmutation RevokeCard($input: RevokeCardInput!) {\n  revokeCard(input: $input) {\n    id\n    ...SeatManagementSeatScreen_Seat\n    ...SeatManagementSeatDrawer_Seat\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchVisit($id: ID!) {\n  visit(id: $id) {\n    ...VisitUnit\n  }\n}",
): (typeof documents)["query FetchVisit($id: ID!) {\n  visit(id: $id) {\n    ...VisitUnit\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n  }\n}",
): (typeof documents)["mutation CreateOrder($input: CreateOrderInput!) {\n  createOrder(input: $input) {\n    id\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchVisits($date: ISO8601Date!, $onlyActive: Boolean!, $onlySettled: Boolean!) {\n  visits(date: $date, onlyActive: $onlyActive, onlySettled: $onlySettled) {\n    ...VisitUnit\n  }\n}\n\nfragment VisitUnit on Visit {\n  id\n  createdAt\n  visitorCount\n  notServedOrderItemExists\n  menuType\n  shopNote\n  settlement {\n    id\n    createdAt\n  }\n  visitSeatMaps {\n    id\n    seat {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      totalVisitCount\n      lastVisitedAt\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n  orderCalculation {\n    totalAmount\n    subtotal\n    tax\n    serviceCharge\n    serviceChargePercentage\n  }\n  orders {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      name\n      quantity\n      price\n      takingTime\n      status\n      createdAt\n    }\n  }\n  orderItems {\n    id\n    name\n    quantity\n    price\n    takingTime\n    status\n    createdAt\n  }\n}",
): (typeof documents)["query FetchVisits($date: ISO8601Date!, $onlyActive: Boolean!, $onlySettled: Boolean!) {\n  visits(date: $date, onlyActive: $onlyActive, onlySettled: $onlySettled) {\n    ...VisitUnit\n  }\n}\n\nfragment VisitUnit on Visit {\n  id\n  createdAt\n  visitorCount\n  notServedOrderItemExists\n  menuType\n  shopNote\n  settlement {\n    id\n    createdAt\n  }\n  visitSeatMaps {\n    id\n    seat {\n      id\n      name\n      table {\n        id\n        name\n        category\n      }\n    }\n  }\n  membershipVisits {\n    id\n    membership {\n      id\n      totalVisitCount\n      lastVisitedAt\n      customer {\n        id\n        profile {\n          id\n          firstNameKana\n          lastNameKana\n        }\n      }\n    }\n  }\n  orderCalculation {\n    totalAmount\n    subtotal\n    tax\n    serviceCharge\n    serviceChargePercentage\n  }\n  orders {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      name\n      quantity\n      price\n      takingTime\n      status\n      createdAt\n    }\n  }\n  orderItems {\n    id\n    name\n    quantity\n    price\n    takingTime\n    status\n    createdAt\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation AuthProvider_DebugCreateAuthSession($input: DebugCreateAuthSessionInput!) {\n  debugCreateAuthSession(input: $input) {\n    ...AuthProvider_ShopUser\n  }\n}\n\nfragment AuthProvider_ShopUser on ShopUser {\n  id\n  email\n  shop {\n    id\n    name\n  }\n}\n\nquery AuthProvider_ShopUser {\n  shopUser {\n    ...AuthProvider_ShopUser\n  }\n}",
): (typeof documents)["mutation AuthProvider_DebugCreateAuthSession($input: DebugCreateAuthSessionInput!) {\n  debugCreateAuthSession(input: $input) {\n    ...AuthProvider_ShopUser\n  }\n}\n\nfragment AuthProvider_ShopUser on ShopUser {\n  id\n  email\n  shop {\n    id\n    name\n  }\n}\n\nquery AuthProvider_ShopUser {\n  shopUser {\n    ...AuthProvider_ShopUser\n  }\n}"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
