import {
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { LogOut, Menu } from "lucide-react";
import { memo, useCallback } from "react";

import { ShopUser } from "src/providers/AuthProvider";

type Props = {
  shopUser: ShopUser | null;
  onLogout: () => void;
};

export const AuthenticatedMenu = memo<Props>(({ shopUser, onLogout }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = useCallback(() => {
    onLogout();
    onClose();
  }, [onLogout, onClose]);

  return (
    <>
      <Button
        as={IconButton}
        icon={<Menu />}
        variant="ghost"
        onClick={onOpen}
      />

      <AuthenticatedDrawer
        shopUser={shopUser}
        isOpen={isOpen}
        onCloseDrawer={onClose}
        onLogout={handleLogout}
      />
    </>
  );
});

type HamburgerMenuProps = {
  shopUser: ShopUser | null;
  isOpen: boolean;
  onCloseDrawer: () => void;
  onLogout: () => void;
};

const AuthenticatedDrawer = memo<HamburgerMenuProps>(
  ({ shopUser, isOpen, onCloseDrawer, onLogout }) => {
    return (
      <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
        <DrawerOverlay />
        <DrawerContent borderLeftRadius="20px">
          <DrawerHeader m={2}>
            <HStack justifyContent="space-between">
              <VStack alignItems="start" spacing={0}>
                {shopUser && (
                  <>
                    <Text fontWeight={800} fontSize="md">
                      {shopUser.currentShop?.name}
                    </Text>

                    <Text fontSize="sm" color="gray.500">
                      {shopUser.email}
                    </Text>
                  </>
                )}

                {!shopUser && (
                  <Text fontWeight={800} fontSize="md">
                    ログインしていません
                  </Text>
                )}
              </VStack>
              <CloseButton onClick={onCloseDrawer} />
            </HStack>
          </DrawerHeader>

          <Divider />

          <DrawerBody m={2}>
            <Stack spacing={2}>
              {shopUser && (
                <Button
                  p={0}
                  leftIcon={<LogOut />}
                  variant="ghost"
                  color="black"
                  onClick={onLogout}
                >
                  ログアウト
                </Button>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  },
);
